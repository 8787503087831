import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useRouteError } from '@remix-run/react';
import type {SentryMetaArgs} from '@sentry/remix';
import { MetaFunction } from "@remix-run/cloudflare";
import type { LinksFunction } from "@remix-run/node";
import stylesheet from "~/tailwind.css?url";

export const meta = ({data}: SentryMetaArgs<MetaFunction<typeof loader>>) => {
  return [
    {
      name: 'sentry-trace',
      content: data?.sentryTrace,
    },
    {
      name: 'baggage',
      content: data?.sentryBaggage,
    },
  ];
};

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: stylesheet },
];

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  return (
    <div className="p-5">
      <Outlet />
    </div>
    ) 
}